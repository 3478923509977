import React from 'react';
import { graphql } from "gatsby"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import Layout from '../components/layout';
import "./template.css"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
          class
          birth
          gender
          studbook
          color
          pedigree
          img {
            childImageSharp{
              gatsbyImageData(layout: FIXED)
            }
          }
      }
      html
    }
  }
`

const Horse = props => {
  const img = getImage(props.data.markdownRemark.frontmatter.img)
  const img2 = getImage(props.data.markdownRemark.frontmatter.img2)
  return (
    <Layout>
      <h1 className="heading"><span className="class-name">{props.data.markdownRemark.frontmatter.class} </span>{props.data.markdownRemark.frontmatter.title}</h1>
      <div className="horse-details-box">
        <p className="horse-details">GENDER <span className="horse-details-blue">{props.data.markdownRemark.frontmatter.gender}</span></p>
        <p className="horse-details">BORN <span className="horse-details-blue">{props.data.markdownRemark.frontmatter.birth}</span></p>
        <p className="horse-details">COLOR <span className="horse-details-blue">{props.data.markdownRemark.frontmatter.color}</span></p>
        <p className="horse-details">STUDBOOK <span className="horse-details-blue">{props.data.markdownRemark.frontmatter.studbook}</span></p>
      </div>
      <div className="horse-images">
        <GatsbyImage image={img} alt={props.data.markdownRemark.frontmatter.title} className="horse-image"/>
        {/* <GatsbyImage image={img2} alt={props.data.markdownRemark.frontmatter.title} className="horse-image2" id="img2-horse"/> */}
      </div>
      <div className="pedigree-box">
        <h1 className="sub-heading">PEDIGREE</h1>
        <iframe src={props.data.markdownRemark.frontmatter.pedigree} id="pedigree"></iframe>
      </div>
    </Layout>
  )
}

export default Horse
